<template>
  <el-dialog
    title="完成评估"
    :visible.sync="dialogVisible"
    width="620px"
  >
    <el-form
      v-if="dialogVisible"
      ref="auditForm"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="120px"
    >
      <el-form-item label="总结">
        <el-input
          v-model="form.sumarize"
          type="textarea"
          maxlength="100"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="auditFinish"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { AssessFinish } from '@/api/pia/pia'
import { error, success } from '@/@core/utils/utils'

export default {
  data() {
    return {
      assessCode: '',
      dialogVisible: false,
      loading: false,
      form: {
        sumarize: '',
        id: '',
      },
    }
  },
  created() {
  },
  methods: {
    auditFinish() {
      this.loading = true
      this.form.id = Number(this.form.id)
      AssessFinish(this.assessCode, this.form).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.dialogVisible = false
          this.$emit('back')
        } else {
          error(resData.msg)
        }
      }).catch(()=>{
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">

</style>
