<template>
  <div class="pia-content">
    <!--进度总览-->
    <b-card>
      <div
        v-show="detailShow"
        v-loading="baseLoading"
      >
        <el-row>
          <el-col
            :span="6"
            class="font-medium-1 mt-50"
          >
            <span class="title">{{ assessmentBaseInfo.assessName }}</span>
          </el-col>
          <el-col
            :span="3"
            class="text-right font-medium-1 mt-50"
          >{{ writeStatusArr.indexOf(status) !== -1 ? '填写进度' : '审核进度' }}：
          </el-col>
          <el-col
            :span="5"
            class="font-medium-1"
          >
            <!--            <span style="position: absolute;top: -10px">{{writeStatusArr.indexOf(status) !== -1 ? piaInfo.answerQuantity+'/'+piaInfo.totalCount : piaInfo.auditQuantity+'/'+piaInfo.totalCount}}</span>-->
            <b-progress
              :max="piaInfo.totalCount"
              class="mt-1"
            >
              <span class="answer-count">{{
                writeStatusArr.indexOf(status) !== -1 ? piaInfo.answerQuantity + '/' + piaInfo.totalCount : piaInfo.auditQuantity + '/' + piaInfo.totalCount
              }}</span>
              <b-progress-bar
                :value="writeStatusArr.indexOf(status) !== -1 ? piaInfo.answerQuantity : piaInfo.auditQuantity"
                :variant="piaInfo.finishCount === piaInfo.totalCount ? 'success' : '#7367F1'"
              />
            </b-progress>
          </el-col>
          <el-col
            :span="5"
            :offset="2"
            class="font-medium-1 mt-50"
          >
            <span class="font-medium-1 mt-50">截止日期：{{ formatDateStr(assessmentBaseInfo.assessEndTime) }}</span>
          </el-col>
          <el-col
            :span="3"
            class="text-right"
          >
            <el-button
              size="small"
              @click="back"
            >
              返 回
            </el-button>
            <el-button
              v-if="writeStatusArr.indexOf(status) !== -1"
              size="small"
              type="primary"
              @click="finishWrite"
            >
              完成填写
            </el-button>
            <el-button
              v-if="assessmentBaseInfo.isProcess && auditStatusArr.indexOf(status) !== -1"
              size="small"
              type="primary"
              @click="finishAudit"
            >
              完成审核
            </el-button>
            <el-button
              v-if="status === 7 || (!assessmentBaseInfo.isProcess && status === 3)"
              size="small"
              type="primary"
              @click="finishAuditDialogShow"
            >
              完成评估
            </el-button>
          </el-col>
        </el-row>
      </div>
    </b-card>

    <!--问题详情-->
    <b-row class="match-height">
      <!--评估提纲-->
      <b-col
        xl="3"
        md="3"
      >
        <b-card>
          <el-row
            :span="3"
            class="font-medium-1 mt-10"
            style="margin-bottom: 12px"
          >
            <span class="title">评估提纲</span>
          </el-row>

          <div v-loading="treeLoading">
            <div>
              <el-tree
                ref="elTree"
                :indent="10"
                accordion
                node-key="id"
                :default-expanded-keys="expandedKeys"
                :highlight-current="true"
                :data="treeData"
                :props="defaultProps"
                @node-click="nodeClick"
              >
                <div
                  slot-scope="{ node, data }"
                  style="width: 90%"
                >
                  <div>
                    <span
                      :title="data.name"
                      class="custom-name"
                    >{{ data.name }}</span>
                    <span
                      v-if="writeStatusArr.indexOf(status) !== -1 && (data.totalCount - data.answerQuantity) > 0"
                      class="b-avatar b-avatar-sm badge-light-danger rounded-circle"
                    >
                      <span
                        class="b-avatar-text"
                      ><span>{{ data.totalCount - data.answerQuantity }}</span>
                      </span>
                    </span>
                    <span
                      v-if="assessmentBaseInfo.isProcess && auditStatusArr.indexOf(status) !== -1 && (data.totalCount - data.auditQuantity) > 0"
                      class="b-avatar b-avatar-sm badge-light-danger rounded-circle"
                    >
                      <span
                        class="b-avatar-text"
                      ><span>{{ data.totalCount - data.auditQuantity }}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </el-tree>
            </div>

          </div>
        </b-card>
      </b-col>

      <!--评估内容-->
      <b-col
        v-loading="qLoading"
        xl="9"
        md="9"
      >
        <b-card>
          <el-row
            :span="3"
            class="font-medium-1 mt-10"
            style="margin-bottom: 12px"
          >
            <span class="title">评估内容</span>
          </el-row>
          <div
            v-loading="answerLoading"
            class="question-wrap"
            :style="{maxHeight: pageH - 250 + 'px'}"
          >
            <app-timeline v-if="showQuestionCount > 0">
              <app-timeline-item
                v-for="(detailItem,index) in questionList"
                v-if="detailItem.isCanShow"
                :key="index"
                :variant="getVariant(detailItem)"
              >
                <b-row>
                  <!--题目-->
                  <b-col cols="10">
                    <div
                      class="flex-start"
                      style="align-items: baseline"
                    >
                      <div class="answer-title"><span
                                                  :style="detailItem.isMustAnswer ? {opacity: 1} : {opacity: 0}"
                                                  style="color:#ec151b;vertical-align: middle;"
                                                >* </span>
                        <span class="mr10">{{ detailItem.seq }}、 {{ detailItem.title }} </span>
                      </div>
                      <el-tooltip
                        v-if="detailItem.haveAffiliate"
                        content="附属信息"
                        placement="top"
                        effect="light"
                      >
                        <i
                          class="el-icon-info cursor-pointer mr10"
                          style="font-size: 16px;color: #0d40b2;"
                          @click="questionDrawerShow(detailItem)"
                        />
                      </el-tooltip>
                      <el-tooltip
                        v-if="detailItem.haveRisk && (auditStatusArr.indexOf(status) !== -1 || finishStatusArr.indexOf(status) !== -1)"
                        content="风险"
                        placement="top"
                        effect="light"
                      >
                        <i
                          class="icon-fengxian iconfont cursor-pointer"
                          style="font-size: 16px;color: rgb(234, 84, 85);"
                          @click="diskDrawerShow(detailItem)"
                        />
                      </el-tooltip>
                    </div>
                    <div class="ml-2">
                      <el-radio-group
                        v-if="detailItem.answerWay === 1"
                        v-model="detailItem.answer"
                        :disabled="writeStatusArr.indexOf(status) === -1"
                        @change="qRadioChange(detailItem)"
                      >
                        <div
                          v-for="(item, indexOpt) in detailItem.optionList"
                          :key="indexOpt"
                        >
                          <el-radio

                            :label="item"
                          >
                            {{ item }}
                          </el-radio>
                        </div>
                      </el-radio-group>
                      <el-checkbox-group
                        v-if="detailItem.answerWay === 2"
                        v-model="detailItem.answer"
                        :disabled="writeStatusArr.indexOf(status) === -1"
                        @change="qRadioChange(detailItem)"
                      >
                        <div
                          v-for="(item,indexOpt) in detailItem.optionList"
                          :key="indexOpt"
                        >
                          <el-checkbox

                            :label="item"
                          >
                            {{ item }}
                          </el-checkbox>
                        </div>
                      </el-checkbox-group>
                      <el-input
                        v-if="detailItem.answerWay === 3"
                        v-model="detailItem.answer"
                        type="textarea"
                        :autosize="autosize"
                        :disabled="writeStatusArr.indexOf(status) === -1"
                        @blur="qRadioChange(detailItem)"
                      />
                      <div v-if="detailItem.autoAnswer.autoAnswerMsg" style="color: rgb(255, 159, 67);font-size: 12px">{{detailItem.autoAnswer.autoAnswerMsg}}</div>
                      <div
                        v-if="detailItem.operateRecords.length>0 && status > 2"
                        style="color: #8c939d;opacity: 0.7;margin-top: 5px;"
                      >
                        <div
                          v-for="(auditItem,k) in detailItem.operateRecords"
                          :key="k"
                        >
                          <span>{{ auditItem.operateTime }}</span>
                          <span
                            style="color: #0d40b2"
                            class="ml10 mr10"
                          >{{ auditItem.operator }}</span>
                          <span
                            style="color: #ff9f43"
                            class="mr10"
                          >{{ operateTypeObj[auditItem.operateType] }}</span>
                          <span>{{ auditItem.operateValue || auditItem.operateResult }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="detailItem.haveAnnex"
                      class="ml-2 flex-start"
                    >
                      <div>
                        <span
                          :style="writeStatusArr.indexOf(status) === -1 ? (detailItem.annexSavePath ? {opacity: 1} : {opacity: 0}) : {} "
                        >附件：</span>
                        <i
                          v-if="writeStatusArr.indexOf(status) !== -1"
                          class="el-icon-upload cursor-pointer mr10"
                          style="font-size: 18px;color: #0d40b2"
                          @click="uploadFileClick(index)"
                        />
                        <i
                          v-if="detailItem.annexSavePath"
                          class="el-icon-download cursor-pointer mr10"
                          style="font-size: 16px;color: #0d40b2"
                          @click="downloadFile(detailItem)"
                        />
                        <i
                          v-if="detailItem.annexSavePath && writeStatusArr.indexOf(status) !== -1"
                          class="el-icon-circle-close cursor-pointer"
                          style="font-size: 16px;color: #ea5455"
                          @click="removeFile(detailItem)"
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    class="pl-1 text-right"
                    cols="2"
                  >
                    <el-switch
                      v-if="assessmentBaseInfo.isProcess && auditStatusArr.indexOf(status) !== -1"
                      :value="detailItem.auditResult"
                      active-color="rgb(19, 206, 102)"
                      inactive-color="rgb(255, 73, 73)"
                      :active-value="1"
                      :inactive-value="2"
                      @change="switchClick(detailItem)"
                    />
                  </b-col>
                </b-row>
                <div>
                  <input
                    :id="'uploadFileInput'+index"
                    type="file"
                    style="display: none"
                    @change="uploadFileInputChange(detailItem,index)"
                  >
                </div>
              </app-timeline-item>
            </app-timeline>
            <el-empty v-else />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <el-dialog
      title="驳回"
      :show-close="false"
      :visible.sync="questionAuditVisible"
      width="620px"
      @close="questionAuditDialogClose"
    >
      <el-form
        ref="questionAuditForm"
        :model="questionAuditForm"
        label-width="80px"
      >
        <el-form-item
          label="驳回原因"
          prop="auditInfo"
          :rules="[ { required: true, message: '驳回原因不能为空', trigger: 'change' }]"
        >
          <el-input
            v-model="questionAuditForm.auditInfo"
            :autosize="autosize"
            type="textarea"
            placeholder="请输入审核信息"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          type="secondary"
          @click="questionAuditVisible = false"
        >
          取消
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="commitQuestionAudit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
    <AssessmentUploadFile
      ref="assessmentUploadFile"
      @handleFilter="getQuestionList"
    />
    <FinishAudit
      ref="finishAudit"
      @back="back"
    />
    <QuestionInfoDrawer ref="questionInfoDrawer" />
    <RiskInfoDrawer ref="riskInfoDrawer" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  CommitAnswer,
  GetAssessQuestionList,
  GetAssessQuestionType,
  WriteFinish,
  QuestionAudit,
  DownloadAnnex,
  RemoveAnnex,
  AssementUpLoadAttachment,
  GetrateProgress, GetAssessBaseInfo, AuditFinish,
} from '@/api/pia/pia'
import { error, success, changeBreadcrumb } from '@core/utils/utils'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AssessmentUploadFile from '@/views/assessment/AssessmentUploadFile.vue'
import FinishAudit from '@/views/assessment/FinishAudit.vue'
import QuestionInfoDrawer from '@/views/assessment/QuestionInfoDrawer.vue'
import RiskInfoDrawer from '@/views/assessment/RiskInfoDrawer.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    AssessmentUploadFile,
    FinishAudit,
    QuestionInfoDrawer,
    RiskInfoDrawer,
  },
  data() {
    return {
      questionAuditVisible: false,
      questionAuditForm: {
        assessId: 0,
        auditInfo: '',
        auditResults: '',
        questionCode: '',
      },
      questionList: [],
      t: Number(this.$route.query.t),
      assessCode: this.$route.query.c,
      assessId: Number(this.$route.query.id),
      currentNode: {},
      variantObj: {
        0: 'danger',
        1: 'secondary',
        2: 'success',
        3: 'success',
      },
      dialogVisible: false,
      activeIndex: 0,
      qLoading: false,
      baseLoading: false,
      answerLoading: false,
      auditForm: {
        summary: '',
        piaAssessId: Number(this.$route.query.id),
      },
      status: Number(this.$route.query.s),
      id: this.$route.query.id,
      expandedKeys: [],
      detailShow: true,
      treeLoading: false,
      title: '',
      assessBeginTime: '',
      assessEndTime: '',
      piaInfo: {
        totalCount: 0,
        answerQuantity: 0,
        auditQuantity: 0,
      },
      loading: false,
      formLoading: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      form: {
        riskList: [],
        overruleReason: '',
        assessDetailId: '',
        result: '',
      },
      completeAnswer: false,
      activeChildNode: null,
      assessmentType: Number(this.$route.query.t), // 评估类型 1 PIA评估
      pageTitleObj: {
        1: '合规评估',
        2: '合规评估',
        3: '合规评估',
        4: '第三方合规管理',
        5: '第三方合规评估',
      },
      subTitleObj: {
        1: 'PIA评估',
        2: '认证评估',
        3: '快速评估',
        4: '个人信息共享评估',
        5: '第三方评估',
      },
      routePathObj: {
        1: '/pia',
        2: '/compliance-analysis',
        3: '/compliance-list',
        4: '/sharedAssessment',
        5: '/thirdPartCompliance',
      },
      writeStatusArr: [1, 2, 4],
      auditStatusArr: [3, 5, 6],
      finishStatusArr: [7, 8],
      showQuestionCount: 0,
      // OperateType int // 操作类型 1：提交答案 2:提交附件 3：审核通过 4：审核驳回
      operateTypeObj: {
        1: '提交答案',
        2: '提交附件',
        3: '审核通过',
        4: '审核驳回',
        5: '移除附件',
      },
      assessmentBaseInfo: {
        assessName: '',
        assessEndTime: '',
      },
      purview: {
        finishPurview: false,
      },
    }
  },
  created() {
    this.getAssessBaseInfo()
    this.getQuestionType()
    this.getrateProgress()
  },
  mounted() {
    setTimeout(() => {
      this.navActiveInit(1, this.subTitleObj[this.assessmentType], this.pageTitleObj[this.assessmentType])
    }, 1000)
  },
  destroyed() {
    this.navActiveInit(2, this.subTitleObj[this.assessmentType], this.pageTitleObj[this.assessmentType], this.routePathObj[this.assessmentType])
  },
  methods: {
    finishAudit() {
      AuditFinish(this.assessCode, this.assessId)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.back()
          } else {
            error(resData.msg)
          }
        })
    },
    // 获取评估基本信息
    getAssessBaseInfo() {
      GetAssessBaseInfo(this.assessCode, this.id)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.assessmentBaseInfo = resData.data
          }
        })
    },
    // 获取评估进度
    getrateProgress() {
      GetrateProgress(this.assessCode, this.id)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.piaInfo = resData.data
          }
        })
    },
    // 上传
    uploadDialogShow(item) {
      this.$refs.assessmentUploadFile.dialogVisible = true
      this.$refs.assessmentUploadFile.form.assessId = this.id
      this.$refs.assessmentUploadFile.form.qCode = item.code
      this.$refs.assessmentUploadFile.form.assessCode = this.assessCode
    },
    uploadFileClick(index) {
      document.getElementById(`uploadFileInput${index}`)
        .click()
    },
    // 上传文件
    uploadFileInputChange(item, index) {
      const uploadDom = document.getElementById(`uploadFileInput${index}`)
      const formData = new FormData()
      formData.append('assessId', this.id)
      formData.append('qCode', item.code)
      formData.append('assessCode', this.assessCode)
      if (uploadDom.files.length > 0) {
        formData.append('file', uploadDom.files[0])
      }
      this.answerLoading = true
      AssementUpLoadAttachment(this.assessCode, formData)
        .then(res => {
          uploadDom.value = ''
          this.answerLoading = false
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.getQuestionList()
          } else {
            error(resData.msg)
          }
        })
        .catch(() => {
          this.answerLoading = false
        })
    },
    // 问题状态颜色
    getVariant(detailItem) {
      let variant = ''
      // eslint-disable-next-line no-param-reassign
      detailItem.auditList = detailItem.auditList ? detailItem.auditList : []
      if (this.writeStatusArr.indexOf(this.status) !== -1) {
        variant = detailItem.answer.length > 0 ? 'success' : 'secondary'
        // 重新填写
        if (detailItem.answerStatus === 2) {
          variant = 'danger'
        }
      }
      if (this.auditStatusArr.indexOf(this.status) !== -1) {
        variant = detailItem.auditResult > 1 ? 'danger' : 'success'
      }
      return variant
    },
    // 提交问题审核
    commitQuestionAudit(item) {
      const hasAudit = item.auditResult
      this.answerLoading = true
      QuestionAudit(this.assessCode, this.questionAuditForm)
        .then(res => {
          this.answerLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.questionAuditVisible = false
            success(resData.msg)
            this.getrateProgress()
            this.getQuestionList()
            if (!hasAudit) {
              // 更新左边问题数量
              this.refreshAnswerTotal()
            }
          } else {
            error(resData.msg)
          }
        })
        .catch(() => {
          this.answerLoading = false
        })
    },
    // 驳回弹框弹框显示
    switchClick(item) {
      this.questionAuditForm.auditResults = item.auditResult === 1 ? 2 : 1
      this.questionAuditForm.assessId = this.assessId
      this.questionAuditForm.questionCode = item.code
      // 驳回弹框显示
      if (item.auditResult === 1) {
        this.questionAuditVisible = true
      } else {
        // 直接通过
        this.commitQuestionAudit(item)
      }
    },
    // 驳回弹框关闭
    questionAuditDialogClose() {
      this.questionAuditForm.auditInfo = ''
      this.$refs.questionAuditForm.resetFields()
    },
    // 刷新答题数量
    refreshAnswerTotal() {
      this.expandedKeys = [this.currentNode.id]
      GetAssessQuestionType(this.assessCode, this.assessId)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.treeData = resData.data
            // this.$refs.elTree.setCurrentKey(this.currentNode.id)
            this.$nextTick(() => {
              this.expandedKeys.push(this.currentNode.id)
              this.$refs.elTree.setCurrentKey(this.currentNode.id)
            })
          }
        })
    },
    // 填写答题
    qRadioChange(question) {
      // this.answerLoading = true
      // 判断是否已经答过
      const hasAnswer = question.answerStatus
      let answer = ''
      if (question.answerWay === 1 || question.answerWay === 3) {
        answer = question.answer
      }
      if (question.answerWay === 2) {
        // answer = question.answer.join(',')
        answer = JSON.stringify(question.answer)
      }
      CommitAnswer(this.assessCode, {
        answer,
        questionCode: question.code,
        assessId: Number(this.assessId),
      })
        .then(res => {
          this.answerLoading = false
          const resData = res.data
          if (resData.code === 0) {
            // success(resData.msg)
            // this.setShowQuestion(question)
            this.getrateProgress()
            this.getQuestionList()
            if (!hasAnswer) {
              // 更新左边问题数量
              this.refreshAnswerTotal()
            }
          } else {
            if (question.answerWay === 1 || question.answerWay === 3) {
              // eslint-disable-next-line no-param-reassign
              question.answer = ''
            }
            if (question.answerWay === 2) {
              // eslint-disable-next-line no-param-reassign
              question.answer = []
            }
            error(resData.msg)
          }
        })
    },
    back() {
      const pathObj = {
        1: '/pia',
        2: '/compliance-analysis',
        3: '/compliance-list',
        4: '/sharedAssessment',
        5: '/thirdPartCompliance',
      }
      this.$router.push({ path: pathObj[this.t] })
    },
    nodeClick(data) {
      this.currentNode = data
      this.getQuestionList()
    },
    // 设置子题显示隐藏
    setShowQuestion(item) {
      if (item.showQuestionOption && item.showQuestionOption.length > 0) {
        item.showQuestionOption.forEach(q => {
          if (q) {
            const flag = q.options.some(o => item.answer.includes(o))
            this.questionList.forEach(item1 => {
              if (item1.code === q.childCode) {
                // eslint-disable-next-line no-param-reassign
                item1.isCanShow = flag
              }
            })
          }
        })
      }
    },
    // 获取问题列表
    getQuestionList() {
      GetAssessQuestionList(this.assessCode, this.assessId, this.currentNode.id)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.questionList = resData.data
            let seq = 0
            //  转换答案
            this.questionList.forEach(question => {
              if (question.isCanShow) {
                seq += 1
              }
              // eslint-disable-next-line no-param-reassign
              question.seq = seq
              // eslint-disable-next-line no-param-reassign
              question.operateRecords = question.operateRecords ? question.operateRecords : []
              if (question.answerWay === 2) {
                if (question.answer.length > 0) {
                  // eslint-disable-next-line no-param-reassign
                  // question.answer = question.answer.split(',')
                  question.answer = JSON.parse(question.answer)
                } else {
                  // eslint-disable-next-line no-param-reassign
                  question.answer = []
                }
              }
              // 审核状态默认通过
              if (this.auditStatusArr.indexOf(this.status) !== -1 && !question.auditResult) {
                // eslint-disable-next-line no-param-reassign
                question.auditResult = 1
              }
              this.setShowQuestion(question)
              // 匹配答案
              if (question.autoAnswer.autoAnswer && question.answer.length === 0) {
                question.answer = question.autoAnswer.autoAnswer
              }
            })
            // 判断是否全部不显示
            this.showQuestionCount = this.questionList.filter(item => item.isCanShow).length
            // this.questionList = resData.data
          }
        })
    },
    // 获取评估问题类型
    getQuestionType() {
      this.treeLoading = true
      GetAssessQuestionType(this.assessCode, this.assessId)
        .then(res => {
          this.treeLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.treeData = resData.data
            if (this.treeData.length > 0) {
              if (this.treeData[0].children) {
                this.currentNode = this.treeData[0].children.length > 0 ? this.treeData[0].children[0] : this.treeData[0]
              } else {
                this.currentNode = this.treeData[0]
              }
              this.expandedKeys.push(this.currentNode.id)
              this.$nextTick(() => {
                this.$refs.elTree.setCurrentKey(this.currentNode.id)
              })
              this.getQuestionList()
            }
          }
        })
        .catch(() => {
          this.treeLoading = false
        })
    },
    // 完成填写
    finishWrite() {
      this.$confirm('确认完成填写吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          WriteFinish(this.assessCode, this.id)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.back()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    // 完成审核弹框显示
    finishAuditDialogShow() {
      this.$refs.finishAudit.dialogVisible = true
      this.$refs.finishAudit.assessCode = this.assessCode
      this.$refs.finishAudit.form.id = this.id
    },
    // 附属信息侧滑显示
    questionDrawerShow(item) {
      this.$refs.questionInfoDrawer.drawerVisible = true
      this.$refs.questionInfoDrawer.assessCode = this.assessCode
      this.$refs.questionInfoDrawer.assessId = this.id
      this.$refs.questionInfoDrawer.qCode = item.code
      this.$refs.questionInfoDrawer.title = item.title
    },
    // 风险侧滑显示
    diskDrawerShow(item) {
      this.$refs.riskInfoDrawer.drawerVisible = true
      this.$refs.riskInfoDrawer.assessCode = this.assessCode
      this.$refs.riskInfoDrawer.assessId = this.id
      this.$refs.riskInfoDrawer.qCode = item.code
      this.$refs.riskInfoDrawer.title = item.title
    },
    // 下载附件
    downloadFile(item) {
      DownloadAnnex(this.assessCode, this.id, item.code)
    },
    // 移除附件
    removeFile(item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          RemoveAnnex(this.assessCode, this.id, item.code)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getQuestionList()
              } else {
                error(resData.msg)
              }
            })
        })
    },

  },
}
</script>

<style>
.question-wrap .timeline-item:last-of-type:after {
  background: #ebe9f1 !important;
}

.question-wrap .el-radio__label,
.question-wrap .el-checkbox__label {
  width: 150%;
  vertical-align: middle;
  white-space: normal;
  display: inline-block;
}

.question-box .el-checkbox__label {
  color: #515a6e;
  font-size: 12px;
}

.tree-wrap,
.question-content-wrap {
  border: 1px solid #dcdfe6;
  padding: 10px;
  border-radius: 4px;
  height: 100%;
  /*overflow:scroll;*/
}

.tree-wrap {
  /*overflow-y: scroll;*/
}

.pia-content {
  height: 100%;
}

.gist-item {
  padding: 5px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
}

.gist-item.is-active {
  background: #0d40b2;
  color: #ffffff;
}

.answer-active {
  background: #0d40b2;
  color: #ffffff !important;
  /*transform: scale(1.2);*/
}

.answer-reject,
.answer-reject .check-box,
.answer-reject i {
  border-color: #f56c6c !important;
  color: #f56c6c !important;
}

.answer-disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #7f7f7f;
}

.completed-btn {
  position: absolute;
  top: 10px;
  right: 24px;
}

/*tree */
[dir="ltr"] .el-badge__content.is-fixed {
  right: 0;
  top: 3px;
}

/*title*/
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  margin-bottom: 2px;
}

.switch-component {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #dadada;
  border-radius: 30px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  transition: all 0.2s ease;
}

.switch-component::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.switch-component:checked {
  background-color: #86c0fa;
}

.switch-component:checked::after {
  left: 50%;
}

.pia-content .el-checkbox__label {
  width: 150%;
  vertical-align: middle;
  white-space: normal;
}

.custom-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.question-wrap {
  overflow-y: scroll;
  overflow-x: hidden;
}

.pop-content {
  max-height: 500px;
  white-space: pre-wrap;
  overflow: auto;
}

.progress {
  position: relative;
}

.answer-count {
  position: absolute;
  top: 5px;
  left: 10px;
  color: #fff;
}

.answer-title {
  font-family: inherit;
  font-weight: 500;
  color: #5e5873;
}
</style>
