<template>
  <el-drawer
    :title="title"
    :size="800"
    :visible.sync="drawerVisible"
    @open="drawerOpen"
  >
    <div
      v-if="drawerVisible"
      style="padding: 0 20px"
    >
      <el-tabs type="border-card">
        <el-tab-pane
          v-for="(item,index) in riskList"
          :key="index"
          :label="'风险'+(index+1)"
        >
          <el-descriptions :column="1">
            <el-descriptions-item label="风险名称">
              {{ item.riskName }}
            </el-descriptions-item>
            <el-descriptions-item label="风险等级">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="levelColorObj[item.riskLevel]"
              >
                {{ levelObj[item.riskLevel] }}
              </b-badge>
            </el-descriptions-item>
            <el-descriptions-item label="风险编号">
              {{ item.riskCode }}
            </el-descriptions-item>
            <el-descriptions-item label="风险描述">
              {{ item.description }}
            </el-descriptions-item>
            <el-descriptions-item label="风险说明">
              {{ item.explanation }}
            </el-descriptions-item>
            <el-descriptions-item label="处置建议">
              {{ item.disposalAdvice }}
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
    </div>

  </el-drawer>
</template>

<script>
import { GetQuestionRisk } from '@/api/pia/pia'

export default {
  data() {
    return {
      drawerVisible: false,
      title: '',
      assessCode: '',
      assessId: '',
      qCode: '',
      riskList: [],
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
        1: 'success',
      },
      levelObj: {
        1: '极低',
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
    }
  },
  methods: {
    drawerOpen() {
      this.getDetail()
    },
    getDetail() {
      GetQuestionRisk(this.assessCode, this.assessId, this.qCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.riskList = resData.data
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
