<template>
  <el-drawer
    :title="title"
    :size="800"
    :visible.sync="drawerVisible"
    @open="drawerOpen"
  >
    <div
      v-if="drawerVisible"
      style="padding: 0 20px"
    >
      <el-tabs type="border-card">
        <el-tab-pane
          v-if="form.explanation"
          label="说明"
        >
          <div class="break-stale" v-html="form.explanation" />
        </el-tab-pane>
        <el-tab-pane
          v-if="form.precautions"
          label="注意事项"
        >
          <div class="break-stale" v-html="form.precautions" />
        </el-tab-pane>
        <el-tab-pane
          v-if="form.relatedTerm"
          label="相关术语"
        >
          <div class="break-stale" v-html="form.relatedTerm" />
        </el-tab-pane>
        <el-tab-pane
          v-if="form.lawInfoList.length > 0"
          label="相关法律"
        >
          <div
            v-for="(item,index) in form.lawInfoList"
            :key="index"
            class="lawInfo"
          >
            <h4>{{ index+1 }}、{{ item.lawName }}  {{ item.lawDetailCode }}</h4>
            <div class="law-content">
              {{ item.content }}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="form.explainList.length > 0"
          label="解读"
        >
          <div
            v-for="(item,index) in form.explainList"
            :key="index"
            class="lawInfo"
          >
            <h4>解读{{ index+1 }}</h4>
            <div
              class="law-content"
              v-html="item"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="form.caseList.length > 0"
          label="案列"
        >
          <div
            v-for="(item,index) in form.caseList"
            :key="index"
            class="lawInfo"
          >
            <h4>案例{{ index+1 }}</h4>
            <div
              class="law-content"
              v-html="item"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="form.bastPracticeList.length > 0"
          label="最佳实战"
        >
          <div
            v-for="(item,index) in form.bastPracticeList"
            :key="index"
            class="lawInfo"
          >
            <h4>实战{{ index+1 }}</h4>
            <div
              class="law-content"
              v-html="item"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import { GetQuestionAffiliate } from '@/api/pia/pia'

export default {
  data() {
    return {
      drawerVisible: false,
      title: '',
      assessCode: '',
      assessId: '',
      qCode: '',
      form: {
        explanation: '',
        precautions: '',
        questionId: '',
        relatedKnowledge: '',
        relatedTerm: '',
        legalBasis: '',
        lawInfoList: [],
        explainList: [],
        bastPracticeList: [],
        caseList: [],
      },
    }
  },
  methods: {
    drawerOpen() {
      this.getDetail()
    },
    getDetail() {
      GetQuestionAffiliate(this.assessCode, this.assessId, this.qCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.bastPracticeList = this.form.bastPracticeList ? this.form.bastPracticeList : []
          this.form.caseList = this.form.caseList ? this.form.caseList : []
          this.form.explainList = this.form.explainList ? this.form.explainList : []
          this.form.lawInfoList = this.form.lawInfoList ? this.form.lawInfoList : []
        }
      })
    },
  },
}
</script>

<style scoped>
.lawInfo{
  margin-bottom: 20px;
  border-bottom: 1px dotted #DCDFE6;
  padding-bottom: 10px;
}
.law-content{
  text-indent: 20px;
  color: rgb(140, 147, 157);
}
.break-stale{
  white-space: pre-wrap;
}
</style>
